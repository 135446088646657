import { ArrowUpIcon, CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

import "vue-range-component/dist/vue-range-slider.css";
// import VueRangeSlider from "vue-range-component";
import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'
import {
    VueTyper
} from 'vue-typer'

/**
 * Shop checkouts component
 */
export default {
    components: {
        'vue-typer': VueTyper,
        Navbar,
        Footer,
        ArrowUpIcon,
        // VueRangeSlider,
        CheckCircleIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
        RangeSlider
    },
    data() {
        return {
            number: 0,
            concurrent1: '< 50 ผู้ใช้',
            concurrent2: '< 100 ผู้ใช้',
            concurrent3: '< 250 ผู้ใช้',
            concurrent4: '< 500 ผู้ใช้',
            concurrent5: '< 1000 ผู้ใช้',
            concurrent6: '< 2500 ผู้ใช้',
            concurrent7: '< 5000 ผู้ใช้',
            concurrent8: '> 5000 ผู้ใช้',
            totalCalculator: 0,
            perDay: 0,
            perUserDay: 0,

            dataUploadValue: 50,
            timeUseValue: 1,
            liveValue: 1,
            userAccountValue: 50,
            // storageValue: 50,



            dataUploadPrice: 0,
            timeUsePrice: 0,
            livePrice: 0,
            userAccountPrice: 0,
            // storagePrice: 0,


            processStyle: {
                backgroundColor: "#ff850c",
            },
            tooltipStyle: {
                backgroundColor: "#dfdfdf",
            },


        };
    },

    computed: {
        totalValue() {
            return (
                parseInt(this.dataUploadPrice) +
                parseInt(this.timeUsePrice) +
                parseInt(this.livePrice) +
                parseInt(this.userAccountPrice)
                // parseInt(this.storagePrice) +

            );
        },
    },
    mounted() {
        // this.dataUploadValue = this.$route.query.user || 50; //data upload
        // this.timeUseValue = this.$route.query.user || 1; //time use
        // this.liveValue = this.$route.query.live || 1; //live
        // this.userAccountValue = this.$route.query.user || 50; //concurrent
        this.priceCalCulator();

        // this.storageValue = this.$route.query.storage || 50;
        // this.updatePricing();
    },
    methods: {
        async priceCalCulator() {
            let initialParam = {
                uploadTHB: 1.20,
                timeMonth: 30,
                timeTHB: 0.14,
                liveMinute: 60,
                liveTHB: 1.65

            };

            let calPoint = 0;
            if (this.userAccountValue <= 50) {
                calPoint = 0;
            }
            else if (this.userAccountValue > 50 && this.userAccountValue <= 100) { calPoint = 29; }
            else if (this.userAccountValue > 100 && this.userAccountValue <= 250) { calPoint = 60; }
            else if (this.userAccountValue > 250 && this.userAccountValue <= 500) { calPoint = 100; }
            else if (this.userAccountValue > 500 && this.userAccountValue <= 1000) { calPoint = 170; }
            else if (this.userAccountValue > 1000 && this.userAccountValue <= 2500) { calPoint = 230; }
            else if (this.userAccountValue > 2500 && this.userAccountValue <= 5000) { calPoint = 420; }
            else {
                calPoint = 0;
            }



            let calPoints = calPoint * initialParam.timeMonth //เอาพอยท์ไปคูณ30วัน
            let calUpload = this.dataUploadValue * initialParam.uploadTHB; //เอาจำนวน GB ไปคูณ 1.20
            let calTimeUse = (this.timeUseValue * initialParam.timeMonth) * initialParam.timeTHB; //ตอนนี้คูณจากค่าเริ่มต้น 1 เดือน
            let calLive = (this.liveValue * initialParam.liveMinute) * initialParam.liveTHB; // เอาจำนวนชั่วโมงไปคูณ 60 แล้วคู 1.65
            let summary = calUpload + calTimeUse + calLive + calPoints; // รวมค่าบริการเบื้องต้น

            this.totalCalculator = summary.toFixed(2);
            this.perDay = (summary / 30).toFixed(2);
            // this.perUserDay = (summary / this.userAccountValue).toFixed(2);
        },
        async updatePricing() {
            let pricing = await this.calculatePricing();

            let data = pricing.data.result;

            this.dataUploadPrice = data.user;
            this.timeUsePrice = data.user;
            this.livePrice = data.live;
            this.userAccountPrice = data.user;
            // this.storagePrice = data.storage;
        },
        // async calculatePricing() {
        //     return await axios.get(
        //         `/company/calculate?user=` +
        //         this.userAccountValue +
        //         `&storage=` +
        //         this.storageValue +
        //         `&live=` +
        //         this.liveValue
        //     );
        // },
    },
}